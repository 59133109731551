*{
    @apply transition duration-300 ease-in-out;
}

.disabled{
    @apply bg-gray-300 pointer-events-none opacity-50;
}

.animate-spin{
    animation: spin 1s linear infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}